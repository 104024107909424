import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  status: "",
  isLoading: false,
  message: "",
  isError: null,
};

export const fetchAddOrg = createAsyncThunk(
  "org/addOrg",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post(
        "/api/Organization/upsert-organization",
        values
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEditOrg = createAsyncThunk(
  "org/EditOrg",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post(
        "api/Organization/upsert-organization",
        values
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const addOrEditOrgSlice = createSlice({
  name: "addOrEditOrg",
  initialState,
  extraReducers: {
    [fetchAddOrg.fulfilled]: (state, action) => {
      state.status = false;
      state.status = "Success";
      state.isError = null;
    },
    [fetchAddOrg.pending]: (state, action) => {
      state.isLoading = true;
      state.status = "";
      state.isError = null;
    },
    [fetchAddOrg.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = payload;
      state.status = payload;
    },
    [fetchEditOrg.fulfilled]: (state, action) => {
      state.status = false;
      state.status = "Success";
      state.isError = null;
    },
    [fetchEditOrg.pending]: (state, action) => {
      state.isLoading = true;
      state.status = "";
      state.isError = null;
    },
    [fetchEditOrg.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = payload;
      state.status = payload;
    },
  },
});

export default addOrEditOrgSlice.reducer;
