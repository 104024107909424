import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   isAppointmentBooked: "",
   isErrorAppointmentBooked: null,
   isLoadingAppointmentBooked: false,
   statusAppointmentBooked: ""
};

export const fetchAppointmentBooking = createAsyncThunk(
   "AppointmentBooking",
   async (values, { rejectWithValue }) => {
      try {
         const data = axiosInstance.post(`/api/front/book-appointment`, values);
         // console.log('data', data)
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
)

const AppointmentBookingSlice = createSlice({
   name: "AppointmentBookingStats",
   initialState,
   extraReducers: {
      [fetchAppointmentBooking.fulfilled]: (state, action) => {
         state.isAppointmentBooked = true;
         state.isLoadingAppointmentBooked = false;
         state.statusAppointmentBooked = "Success";
         state.isErrorAppointmentBooked = "";
      },
      [fetchAppointmentBooking.pending]: (state, action) => {
         state.isAppointmentBooked = false;
         state.isLoadingAppointmentBooked = true;
         state.statusAppointmentBooked = "pending";
         state.isErrorAppointmentBooked = "";
      },
      [fetchAppointmentBooking.rejected]: (state, action) => {
         state.isAppointmentBooked = false;
         state.isLoadingAppointmentBooked = false;
         state.statusAppointmentBooked = "failed";
         state.isErrorAppointmentBooked = "failed";
      }
   }
});

export default AppointmentBookingSlice.reducer;
