export const appointmentTypeData = [
  {
    id: 1,
    title: "New Patient Exam",
    isSelect: false,
  },
  {
    id: 2,
    title: "Emergency Exam",
    isSelect: false,
  },
  {
    id: 3,
    title: "Aligners & Braces Consultation",
    isSelect: false,
  },
  {
    id: 4,
    title: "Prophy Cleaning",
    isSelect: false,
  },
  {
    id: 5,
    title: "Perio Cleaning",
    isSelect: false,
  },
];
