import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   data: [],
   status: "",
   isError: null,
   isLoading: false,
};

export const fetchGHLPatient = createAsyncThunk(
   'GHL/fetchGHLPatient',
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(`/Api/Patient/searchpatient/${params.clinicid}/contactid/${params.id}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const clearGHLData = createAction('GHL/clearGHLData');

const GHLSlice = createSlice({
   name: "GHLList",
   initialState,
   reducers: {
      resetData(state, action) {
         return initialState
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchGHLPatient.fulfilled, (state, { payload }) => {
            if (payload.status.isSuccess === true) {
               state.data = payload.data;
            } else {
               state.data = [];
            }
            state.isLoading = false;
            state.status = "Success";
            state.isError = false;
         })
         .addCase(fetchGHLPatient.pending, (state) => {
            state.data = [];
            state.isLoading = true;
            state.status = "";
            state.isError = false;
         })
         .addCase(fetchGHLPatient.rejected, (state) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.status = "Rejected";
         })
         .addCase(clearGHLData, (state) => {
            // Reset the state when clearGHLData action is dispatched
            return initialState;
         });
   },
});

export default GHLSlice.reducer;
export const { resetData } = GHLSlice.actions