import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  operatories: [],
  isLoadingOperatories: false,
  statusOperatories: "",
  isErrorOperatories: null,
  providers: [],
  isLoadingProvider: false,
  statusProvider: "",
  isErrorProvider: null,
  appointmentSlots: [],
  isLoading: false,
  isError: null,
  status: "",
  operatoriesDefaultTrue: false,
  // operatoriesDefaultFalse: false,
  providersDefaultTrue: false,
  // providersDefaultFalse: false,
};

export const fetchOperatories = createAsyncThunk(
  "operatories/operatoriesList",
  async (id, { rejectWithValue }) => {
    // console.log('id', id)
    try {
      const { data } = await axiosInstance.get(
        `api/Clinic/get-operatories?id=${id}`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);
export const fetchProviders = createAsyncThunk(
  "providers/providersList",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/Clinic/get-providers?id=${id}`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);
export const fetchDefaultOperatorTrue = createAsyncThunk(
  "providers/DefaultOperatorTrue",
  async (items, { rejectWithValue }) => {
    // console.log("items", items)
    try {
      const { data } = await axiosInstance.post(
        `api/Clinic/update-operatories?operatoryId=${items.dentalOperatoryId}&ClinicId=${items.clinicId}&Default=true`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);
export const fetchDefaultOperatorFalse = createAsyncThunk(
  "providers/DefaultOperatorFalse",
  async (items, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `api/Clinic/update-operatories?operatoryId=${items.dentalOperatoryId}&ClinicId=${items.clinicId}&Default=false`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

export const fetchDefaultProviderTrue = createAsyncThunk(
  "providers/DefaultProviderTrue",
  async (items, { rejectWithValue }) => {
    // console.log("items", items)
    try {
      const { data } = await axiosInstance.post(
        `api/Clinic/update-providers?providerId=${items.dentalProviderId}&ClinicId=${items.clinicId}&Default=true`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);
export const fetchDefaultProviderFalse = createAsyncThunk(
  "providers/DefaultProviderFalse",
  async (items, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `api/Clinic/update-providers?providerId=${items.dentalProviderId}&ClinicId=${items.clinicId}&Default=false`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

const operatoriesSlice = createSlice({
  name: "operatoriesList",
  initialState,
  extraReducers: {
    [fetchOperatories.fulfilled]: (state, { payload }) => {
      if (payload.isSuccess === false) {
        state.operatories = [];
      } else {

        state.operatories = payload;
      }
      state.isLoadingOperatories = false;
      state.statusOperatories = "Success";
      state.isErrorOperatories = null;
    },
    [fetchOperatories.pending]: (state, { payload }) => {
      state.operatories = [];
      state.isLoadingOperatories = true;
      state.statusOperatories = "";
      state.isErrorOperatories = null;
    },
    [fetchOperatories.rejected]: (state, { payload }) => {
      state.operatories = [];
      state.isLoadingOperatories = false;
      state.isErrorOperatories = payload;
      state.statusOperatories = "failed";
    },
    [fetchProviders.fulfilled]: (state, { payload }) => {
      if (payload.isSuccess === false) {
        state.providers = [];
      } else {

        state.providers = payload;
      }
      state.isLoadingProvider = false;
      state.statusProvider = "Success";
      state.isErrorProvider = null;
    },
    [fetchProviders.pending]: (state, { payload }) => {
      state.providers = [];
      state.isLoadingProvider = true;
      state.statusProvider = "";
      state.isErrorProvider = null;
    },
    [fetchProviders.rejected]: (state, { payload }) => {
      state.providers = [];
      state.isLoadingProvider = false;
      state.isErrorProvider = payload;
      state.statusProvider = "failed";
    },
    // fetchDefaultOperatorTrue
    [fetchDefaultOperatorTrue.fulfilled]: (state, { payload }) => {
      state.operatoriesDefaultTrue = true;
    },
    [fetchDefaultOperatorTrue.pending]: (state, { payload }) => {
      state.operatoriesDefaultTrue = false;
    },
    [fetchDefaultOperatorTrue.rejected]: (state, { payload }) => {
      state.operatoriesDefaultTrue = false;
    },

    [fetchDefaultOperatorFalse.fulfilled]: (state, { payload }) => {
      state.operatoriesDefaultTrue = false;
    },
    [fetchDefaultOperatorFalse.pending]: (state, { payload }) => {
      state.operatoriesDefaultTrue = false;
    },
    [fetchDefaultOperatorFalse.rejected]: (state, { payload }) => {
      state.operatoriesDefaultTrue = false;
    },
    // fetchDefaultProviderTrue
    [fetchDefaultProviderTrue.fulfilled]: (state, { payload }) => {
      state.providersDefaultTrue = true;
    },
    [fetchDefaultProviderTrue.pending]: (state, { payload }) => {
      state.providersDefaultTrue = false;
    },
    [fetchDefaultProviderTrue.rejected]: (state, { payload }) => {
      state.providersDefaultTrue = false;
    },

    [fetchDefaultProviderFalse.fulfilled]: (state, { payload }) => {
      state.providersDefaultFalse = true;
    },
    [fetchDefaultProviderFalse.pending]: (state, { payload }) => {
      state.providersDefaultFalse = false;
    },
    [fetchDefaultProviderFalse.rejected]: (state, { payload }) => {
      state.providersDefaultFalse = false;
    },
  },
});

// export const { updateUser } = usersSlice.actions;

export default operatoriesSlice.reducer;
