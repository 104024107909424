import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
   posts: [],
   status: "",
   isError: null,
   isLoading: false,
};

export const fetchFacebook = createAsyncThunk(
   'facebook/fetchFacebook',
   async (_, { rejectWithValue }) => {
      try {
         const response = await fetch("https://graph.facebook.com/v18.0/196956030502310?fields=overall_star_rating%2Crating_count%2Ctalking_about_count%2Cabout%2Cratings&access_token=EAArZBq9ASKo8BO0cGZArkcjkc3rZAOZCU4YIrKRYf01qXz9qWVQZAzBbXqYNzaA3T2D33tIp5FM2zUBTe0CPtspT9wMxxTC2hZBlanijZBjTwAIhExZCo7ZB5l01RAG8QcMYVKHaFMKTsek7D9fVctOlUIIir4HzR4ZC0unZAZCg63ZCKmVcXZB1bqBBBDclIMGRU7cQcZD");

         if (!response.ok) {
            throw new Error('Data Not Found');
         }

         const data = await response.json();
         return data;
      } catch (error) {
         return rejectWithValue(error.message);
      }
   }
);

export const clearFacebookData = createAction('facebook/clearFacebookData');

const faceBookSlice = createSlice({
   name: "facebookList",
   initialState,
   reducers: {
      resetData(state, action) {
         return initialState
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchFacebook.fulfilled, (state, { payload }) => {
            state.posts = payload;
            state.isLoading = false;
            state.status = "Success";
            state.isError = false;
         })
         .addCase(fetchFacebook.pending, (state) => {
            state.posts = [];
            state.isLoading = true;
            state.status = "";
            state.isError = false;
         })
         .addCase(fetchFacebook.rejected, (state) => {
            state.posts = [];
            state.isLoading = false;
            state.isError = true;
            state.status = "Rejected";
         })
         .addCase(clearFacebookData, (state) => {
            // Reset the state when clearFacebookData action is dispatched
            return initialState;
         });
   },
});

export default faceBookSlice.reducer;
export const { resetData } = faceBookSlice.actions