import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
// import { useDispatch, useSelector } from "react-redux";

const AppointmentList = () => {
  // const [isDateInput, setIsDateInput] = useState(false);
  // const [value, onChange] = useState(new Date());

  // const dispatch = useDispatch();
  // const { timeListLoading } = useSelector((state) => state.timeSlot);

  return (
    <Row className="w-100  d-flex align-items-center  p-0 py-1">
      {/* <Col
        md={5}
        sm={12}
        className="d-flex p-0 justify-content-end align-items-center "
      >

      </Col>

      <Col md={7} sm={12} className="p-0">

      </Col> */}
    </Row>
  );
};

export default AppointmentList;
