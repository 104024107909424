import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   PatientOverviewCommunication: [],
   isLoadingPatientOverviewCommunication: false,
   isErrorPatientOverviewCommunication: null,
   statusPatientOverviewCommunication: "",
};
// console.log('PatientOverviewCommunication', initialState.PatientOverviewCommunication)



export const fetchPracticePatientOverviewCommunication = createAsyncThunk(
   "PatientOverviewCommunicationList/PatientOverviewCommunication",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/Patient/get-patientcommunication/${params}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const clearPatientCommunication = createAction('facebook/clearPatientCommunication');

const patientCommunicationSlice = createSlice({
   name: "practiceIQList",
   initialState,
   reducers: {
      resetData(state, action) {
         return initialState
      }
   },
   extraReducers: {
      [fetchPracticePatientOverviewCommunication.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.PatientOverviewCommunication = payload.data;
         } else {
            state.PatientOverviewCommunication = [];
         }
         // state.PatientOverviewCommunication = payload;
         state.isLoadingPatientOverviewCommunication = false;
         state.statusPatientOverviewCommunication = "Success";
         state.isErrorPatientOverviewCollection = null;
      },
      [fetchPracticePatientOverviewCommunication.pending]: (state, { payload }) => {
         state.PatientOverviewCommunication = [];
         state.isLoadingPatientOverviewCommunication = true;
         state.statusPatientOverviewCommunication = "";
         state.isErrorPatientOverviewCollection = null;
      },
      [fetchPracticePatientOverviewCommunication.rejected]: (state, { payload }) => {
         state.PatientOverviewCommunication = [];
         state.isLoadingPatientOverviewCommunication = false;
         state.isErrorPatientOverviewCollection = payload;
         state.statusPatientOverviewCommunication = "bad req";
      },
   },
});

export default patientCommunicationSlice.reducer;
export const { resetData } = patientCommunicationSlice.actions