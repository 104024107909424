import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  schedulars: [],
  isLoading: false,
  isError: null,
  status: '',

  isLoadingBookAppmtSchedular: false,
  statusBookAppmtSchedular: '',
  isErrorBookAppmtSchedular: '',

  ////////////////////
  appoitmentSchedularData:[],
  isLoadingAppoitmentSchedular:false,
  statusAppoitmentSchedular:'',
  isErrorAppoitmentSchedular:''

};
export const fetchSchedular = createAsyncThunk(
  'schedulars/schedule',
  async (newParams, { rejectWithValue }) => {
    // console.log('newParams', newParams);
    try {
      const { data } = await axiosInstance.get(
        `api/Appointment/get-scheduler?ClinicId=${newParams.inputFields}&currentDate=${newParams.currentDate}`
      );
      // console.log('data :>> ', data);
      return data;
    } catch (error) {
      return rejectWithValue('data Not Found');
    }
  }
);

export const fetchBookAppmtSchedular = createAsyncThunk(
  'BookAppmtSchedular/BookAppmtSchedularList',
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post(
        '/api/Front/book-appointmentscheduler',
        values
      );
      // console.log('data2 :>> ', data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAppoitmnetchedular = createAsyncThunk(
  'appoitmnetSchedulars/appoitmnetSchedularsList',
  async (newParams, { rejectWithValue }) => {
    // console.log('newParams', newParams);
    try {
      const { data } = await axiosInstance.get(
        // https://api.dentalbookingonline.com/api/Appointment/get-ApptSchedulerByOp?ClinicId=66&currentDate=2024-11-12
        `api/Appointment/get-ApptSchedulerByOp?ClinicId=${newParams.inputFields}&currentDate=${newParams.currentDate}`
      );
      // console.log('data :>> ', data);
      return data;
    } catch (error) {
      return rejectWithValue('data Not Found');
    }
  }
);

const schedularSlice = createSlice({
  name: 'schedule',
  initialState,
  extraReducers: {
    [fetchSchedular.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.schedulars = payload.data;
      } else {
        state.schedulars = [];
      }
      state.isLoading = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchSchedular.pending]: (state, { payload }) => {
      state.schedulars = [];
      state.isLoading = true;
      state.status = 'request';
      state.isError = null;
    },
    [fetchSchedular.rejected]: (state, { payload }) => {
      state.schedulars = [];
      state.isLoading = false;
      state.isError = payload;
      state.status = 'bad req';
    },
    [fetchBookAppmtSchedular.fulfilled]: (state, action) => {
      state.isLoadingBookAppmtSchedular = false;
      state.statusBookAppmtSchedular = 'Success';
      state.isErrorBookAppmtSchedular = '';
    },
    [fetchBookAppmtSchedular.pending]: (state, action) => {
      state.isLoadingBookAppmtSchedular = true;
      state.statusBookAppmtSchedular = 'pending';
      state.isErrorBookAppmtSchedular = '';
    },
    [fetchBookAppmtSchedular.rejected]: (state, { payload }) => {
      state.isLoadingBookAppmtSchedular = false;
      state.isErrorBookAppmtSchedular = 'Rejected';
      state.statusBookAppmtSchedular = 'Rejected';
    },
    ///////////////////
    [fetchAppoitmnetchedular.fulfilled]: (state, { payload }) => {
      // console.log("payload->",payload);
      if (payload.status.isSuccess === true) {
        state.appoitmentSchedularData = payload.data;
      } else {
        state.appoitmentSchedularData = [];
      }
      state.isLoadingAppoitmentSchedular = false;
      state.statusAppoitmentSchedular = 'Success';
      state.isErrorAppoitmentSchedular = null;
    },
    [fetchAppoitmnetchedular.pending]: (state, { payload }) => {
      state.appoitmentSchedularData = [];
      state.isLoadingAppoitmentSchedular = true;
      state.statusAppoitmentSchedular = 'request';
      state.isErrorAppoitmentSchedular = null;
    },
    [fetchAppoitmnetchedular.rejected]: (state, { payload }) => {
      state.appoitmentSchedularData = [];
      state.isLoadingAppoitmentSchedular = false;
      state.isErrorAppoitmentSchedular = payload;
      state.statusAppoitmentSchedular = 'bad req';
    },
  },
});

export default schedularSlice.reducer;
