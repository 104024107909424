import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  monthsData: [],
  isLoadingMonthsData: false,
  isError: null,
  status: '',


};


export const fetchMonthsData = createAsyncThunk(
  'monthsData/monthsDataList',
  async (params, { rejectWithValue }) => {
    // console.log("months--->params",params);
    try {
      const { data } = await axiosInstance.get(
        // https://api.dentalbookingonline.com/api/Appointment/get-ApptSchedulerByMonths?ClinicId=66&startdate=2024-11-01
        // `api/Dashboard/totalbookingreports/${params.startDate}/${params.endDate}`
         `api/Appointment/get-ApptSchedulerByMonths?ClinicId=${params.inputFields}&startdate=${params.currentDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const monthsDataSlice = createSlice({
  name: 'monthsDataList',
  initialState,
  extraReducers: {
    [fetchMonthsData.fulfilled]: (state, { payload }) => {
        // console.log("months->payload",payload);
      if (payload.status.isSuccess === true) {
        state.monthsData = payload.data;
      } else {
        state.monthsData = [];
      }
      state.isLoadingMonthsData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchMonthsData.pending]: (state, { payload }) => {
      state.isLoadingMonthsData = true;
      state.status = '';
      state.isError = null;
      state.monthsData = [];
    },
    [fetchMonthsData.rejected]: (state, { payload }) => {
      state.isLoadingMonthsData = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.monthsData = [];
    },
  },
});

export default monthsDataSlice.reducer;
