import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   FinancialBasic: [],
   isLoadingFinancialBasic: false,
   isErrorFinancialBasic: null,
   statusFinancialBasic: "",
};

export const fetchFinancialBasic = createAsyncThunk(
   "practiceIQFinance/practiceIQFinancial",
   async (params, { rejectWithValue }) => {
      // console.log('FinancialBasicparams', currentDate)
      try {
         // https://api.dentalbookingonline.com/api/Practice/get-financialbasic?startdate=2023-07-21&enddate=2023-07-21
         const { data } = await axiosInstance.get(`/api/Practice/get-financialbasic?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

const practiceIQFinancialSlice = createSlice({
   name: "practiceIQFinancial",
   initialState,
   extraReducers: {
      [fetchFinancialBasic.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.FinancialBasic = [];
         } else {
            state.FinancialBasic = payload;
         }
         // state.FinancialBasic = payload;
         state.isLoadingFinancialBasic = false;
         state.statusFinancialBasic = "Success";
         state.isErrorFinancialBasic = null;
      },
      [fetchFinancialBasic.pending]: (state, { payload }) => {
         state.FinancialBasic = [];
         state.isLoadingFinancialBasic = true;
         state.statusFinancialBasic = "";
         state.isErrorFinancialBasic = null;
      },
      [fetchFinancialBasic.rejected]: (state, { payload }) => {
         state.FinancialBasic = [];
         state.isLoadingFinancialBasic = false;
         state.isErrorFinancialBasic = payload;
         state.statusFinancialBasic = "bad req";
      },
   },
});


export default practiceIQFinancialSlice.reducer;
