import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   sms: [],
   isLoading: false,
   isError: null,
   status: "",
   isLoadingEditSms: false,
   statusEditSms: "",
   isErrorEditSms: null,
};

export const fetchSms = createAsyncThunk(
   "smss/sms",
   async (id = null, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(`/api/Sms/get-smstemplate`);

         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchEditTemplate = createAsyncThunk(
   "template/EditTemplate",
   async (values, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.post(
            "/api/Sms/edit-smstemplate",
            values
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const patientsSlice = createSlice({
   name: "sms",
   initialState,
   extraReducers: {
      [fetchSms.fulfilled]: (state, { payload }) => {
         state.sms = payload;
         state.isLoading = false;
         state.status = "Success";
         state.isError = null;
      },
      [fetchSms.pending]: (state, { payload }) => {
         state.sms = [];
         state.isLoading = true;
         state.status = "";
         state.isError = null;
      },
      [fetchSms.rejected]: (state, { payload }) => {
         state.sms = [];
         state.isLoading = false;
         state.isError = payload;
         state.status = "bad req";
      },
      [fetchEditTemplate.fulfilled]: (state, action) => {
         state.isLoadingEditSms = false;
         state.statusEditSms = "Success";
         state.isErrorEditSms = null;
      },
      [fetchEditTemplate.pending]: (state, action) => {
         state.isLoadingEditSms = true;
         state.statusEditSms = "";
         state.isErrorEditSms = null;
      },
      [fetchEditTemplate.rejected]: (state, { payload }) => {
         state.isLoadingEditSms = false;
         state.isErrorEditSms = payload;
         state.statusEditSms = payload;
      },

   },
});

export default patientsSlice.reducer;
