import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  productionLeaderData: [],
  isLoadingProductionDataData: false,
  isError: null,
  status: '',
};

export const fetchProducionLeaderBoard = createAsyncThunk(
  'productionLeader/productionLeaderBoardList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/Dashboard/providers/${params.inputFields}/amountdetails/${params.startDate}/${params.endDate}/false`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const productionLeaderBoardSlice = createSlice({
  name: 'productionLeaderBoardList',
  initialState,
  extraReducers: {
    [fetchProducionLeaderBoard.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.productionLeaderData = payload.data;
      } else {
        state.productionLeaderData = [];
      }
      state.isLoadingProductionDataData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchProducionLeaderBoard.pending]: (state, { payload }) => {
      state.isLoadingProductionDataData = true;
      state.status = '';
      state.isError = null;
      state.productionLeaderData = [];
    },
    [fetchProducionLeaderBoard.rejected]: (state, { payload }) => {
      state.isLoadingProductionDataData = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.productionLeaderData = [];
    },
  },
});

export default productionLeaderBoardSlice.reducer;
