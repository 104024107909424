import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   marketingCost: [],
   isLoadingMarketingCost: false,
   isErrorMarketingCost: null,
   statusMarketingCost: "",
   ////////////////////////////////////
   //    UpdateMarketingCost : [],
   //     isLoadingUpdateMarketingCost : false,
   //     statusUpdateMarketingCost : "",
   //     isErrorUpdateMarketingCost : null,
};

export const fetchMarketingCost = createAsyncThunk(
   "MarketingCost/MarketingCostList",
   async (params, { rejectWithValue }) => {
      // console.log('value', value)
      try {
         const { data } = await axiosInstance.get(
            `/api/dashboard/get-calculatekpis?clinicid=${params}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("data Not Found");
      }
   }
);

// export const fetchUpdateMarketingCost = createAsyncThunk(
//     "",
//     async (params, { rejectWithValue }) => {
//        // console.log('value', value)
//        try {
//           const { data } = await axiosInstance.get(
//              ``
//           );
//           return data;
//        } catch (error) {
//           return rejectWithValue("data Not Found");
//        }
//     }
//  );


const settingSlice = createSlice({
   name: "KPISetting",
   initialState,
   extraReducers: {
      [fetchMarketingCost.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.marketingCost = [];
         } else {
            state.marketingCost = payload;
         }
         state.isLoadingMarketingCost = false;
         state.statusMarketingCost = "Success";
         state.isErrorMarketingCost = null
      },
      [fetchMarketingCost.pending]: (state, { payload }) => {
         state.marketingCost = [];
         state.isLoadingMarketingCost = true;
         state.statusMarketingCost = "";
         state.isErrorMarketingCost = null;
      },
      [fetchMarketingCost.rejected]: (state, { payload }) => {
         state.marketingCost = [];
         state.isLoadingMarketingCost = false;
         state.isErrorMarketingCost = payload;
         state.statusMarketingCost = "failed";
      },
      //   [fetchUpdateMarketingCost.fulfilled]: (state, { payload }) => {
      //        state.UpdateMarketingCost = payload;
      //     state.isLoadingUpdateMarketingCost = false;
      //     state.statusUpdateMarketingCost = "Success";
      //     state.isErrorUpdateMarketingCost = null
      //  },
      //  [fetchUpdateMarketingCost.pending]: (state, { payload }) => {
      //     state.UpdateMarketingCost = [];
      //     state.isLoadingUpdateMarketingCost = true;
      //     state.statusUpdateMarketingCost = "";
      //     state.isErrorUpdateMarketingCost = null;
      //  },
      //  [fetchUpdateMarketingCost.rejected]: (state, { payload }) => {
      //     state.UpdateMarketingCost = [];
      //     state.isLoadingUpdateMarketingCost = false;
      //     state.isErrorUpdateMarketingCost = payload;
      //     state.statusUpdateMarketingCost = "failed";
      //  },

   },
});

export default settingSlice.reducer;
