import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";
// import moment from "moment";
var moment = require("moment-timezone");

const initialState = {
  timeList: [],
  timeListLoading: true,
  timeListError: false,
};

export const timeSlotSlice = createSlice({
  name: "timeSlotType",
  initialState,
  reducers: {
    timeListPending: (state) => {
      state.timeList = [];
      state.timeListLoading = true;
      state.timeListError = false;
    },
    timeListErrored: (state, { payload }) => {
      state.timeList = [];
      state.timeListLoading = false;
      state.timeListError = payload;
    },
    selTimeList: (state, { payload }) => {
      state.timeList = payload;
      state.timeListLoading = false;
      state.timeListError = false;
    },
    timeListDetails: (state, { payload }) => {
      const tempSlot = [...payload.slots];
      // console.log('tempSlot', tempSlot)
      const slotArray = tempSlot.map((item, idx) => {

        return {
          id: idx + 1,
          slotId: item.sId,
          day: new Date(item.time).getDate(),
          itemDate: moment(item.time).format("YYYY-MM-DD"),
          time: item.time,
          // operatoryId: item.operatory_id,
          selected: false,
          itemTime: moment(item.time).format('hh:mm A'),
        };
      });
      state.timeList = slotArray;
      state.timeListLoading = false;
      state.timeListError = false;
    },
  },
});

// Destructure and export the plain action creators
export const {
  timeListPending,
  timeListErrored,
  timeListDetails,
  selTimeList,
} = timeSlotSlice.actions;


export default timeSlotSlice.reducer;

export const getTimeSlotList =
  (params) => async (dispatch) => {
    try {
      dispatch(timeListPending());
      const { data } = await axiosInstance.get(
        `api/front/appointmentslots?operatoryId=${params.operatoryId}&Clinicid=${params.id}&providerId=${params.providerId}`
      );
      const data1 = data.map((el) => {
        return { time: el.startTime, sId: el.id }
      })

      const data2 = { slots: data1 };
      // console.log('data2', data2)
      dispatch(timeListDetails(data2));
    } catch (error) {
      dispatch(timeListErrored(true));
    }
  };
