import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  patientsList: [],
  isLoading: false,
  isError: null,
  status: "",


  patientDetailsList: [],
  patientDetailsIsLoading: false,
  patientDetailsStatus: "",
  patientDetailsIsError: null,


  PatientsID: [],
  PatientsIDIsLoading: false,
  PatientsIDIsError: null,
  PatientsIDStatus: "",
};

export const fetchPatients = createAsyncThunk(
  "patients/patientsList",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/Patient/get-Patients?ClinicId=${params.inputFields}&startDate=${params.startDate}&endDate=${params.endDate}`);
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

export const fetchPatientDetails = createAsyncThunk(
  "patientDetails/patientDetailsList",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/Patient/get-patientsms?pid=${params.id}&clinicid=${params.clinicId}`);
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

// export const fetchPatientGlobal = createAsyncThunk(
//   "PatientGlobal/PatientGlobalList",
//   async (params, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosInstance.get(``);
//       return data;
//     } catch (error) {
//       return rejectWithValue("Data Not Found");
//     }
//   }
// );

export const fetchPatientsID = createAsyncThunk(
  "PatientsID/PatientsIDList",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/Patient/get-FindPatients?ClinicId=${params.inputFields}&search=${params.text}`);
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);


const patientsSlice = createSlice({
  name: "patientsList",
  initialState,
  extraReducers: {
    [fetchPatients.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.patientsList = payload.data;
      } else {
        state.patientsList = [];
      }
      state.isLoading = false;
      state.status = "Success";
      state.isError = null;
    },
    [fetchPatients.pending]: (state, { payload }) => {
      state.patientsList = [];
      state.isLoading = true;
      state.status = "";
      state.isError = null;
    },
    [fetchPatients.rejected]: (state, { payload }) => {
      state.patientsList = [];
      state.isLoading = false;
      state.isError = payload;
      state.status = "bad req";
    },
    [fetchPatientDetails.fulfilled]: (state, { payload }) => {
      state.patientDetailsList = payload;
      state.patientDetailsIsLoading = false;
      state.patientDetailsStatus = "Success";
      state.patientDetailsIsError = false;
    },
    [fetchPatientDetails.pending]: (state, { payload }) => {
      state.patientDetailsList = [];
      state.patientDetailsIsLoading = true;
      state.patientDetailsStatus = "";
      state.patientDetailsIsError = false;
    },
    [fetchPatientDetails.rejected]: (state, { payload }) => {
      state.patientDetailsList = [];
      state.patientDetailsIsLoading = false;
      state.patientDetailsIsError = true;
      state.patientDetailsStatus = "Rejected";
    },

    [fetchPatientsID.fulfilled]: (state, { payload }) => {
      // console.log('payload', payload)
      if (payload.status.isSuccess === true) {
        state.PatientsID = payload.data;
      } else {
        state.PatientsID = [];
      }
      state.PatientsIDIsLoading = false;
      state.PatientsIDStatus = "Success";
      state.PatientsIDIsError = false;
    },
    [fetchPatientsID.pending]: (state, { payload }) => {
      state.PatientsID = [];
      state.PatientsIDIsLoading = true;
      state.PatientsIDStatus = "";
      state.PatientsIDIsError = false;
    },
    [fetchPatientsID.rejected]: (state, { payload }) => {
      state.PatientsID = [];
      state.PatientsIDIsLoading = false;
      state.PatientsIDIsError = true;
      state.PatientsIDStatus = "Rejected";
    }
  },
});

export default patientsSlice.reducer;
