import moment from 'moment';
import React, { useEffect } from 'react';
import { createContext, useState } from 'react';
import 'moment-timezone';

export const Context = createContext();

const AppCentralisedDataStore = ({ children }) => {
  const [taxIDValue, setTaxIDValue] = useState('');
  const [userSelected, setUserSelected] = useState(0);
  const [clinicName, setClinicName] = useState(0);
  const [selectedCustomDashboard, setSelectedCustomDashboard] = useState({
    name: '',
    id: 0,
  });
  const [kpiAddAccess, setKPIAddAccess] = useState(false);
  const [patientInputFieldsValue, setPatientInputFieldsValue] = useState('');
  const [patientInputFields, setPatientInputFields] = useState(0);
  const [inputFieldsValue, setInputFieldsValue] = useState('');
  const [clinicId, setClinicId] = useState(0);
  const [userId, setUserId] = useState(null);
  const [inputFields, setInputFields] = useState(0);
  const [groupByDate, setGroupByDate] = useState(false);
  const [groupByClinic, setGroupByClinic] = useState(false);
  const [isOpd, setIsOpd] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).tz('America/Menominee').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).tz('America/Menominee').format('YYYY-MM-DD')
  );
  // console.log('inputFields', inputFields, inputFieldsValue)
  const [defaultDate, setDefaultDate] = useState([
    moment(new Date()).tz('America/Menominee'),
    moment(new Date()).tz('America/Menominee'),
  ]);

  const [ptAcceptFilter, setPTAcceptFilter] = useState(true);

  //////////////// HELP MESSAGE ///////////////////
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState('');
  //////////////// HELP MESSAGE ///////////////////

  /////////////////////  ADMIN REPORT ////////////////
  const [clinicReport, setClinicReport] = useState({
    id: 0,
    name: '',
  });
  /////////////////////  ADMIN REPORT ////////////////
  const path = window.location.pathname;

  return (
    <Context.Provider
      value={{
        patientInputFields,
        setPatientInputFields,
        patientInputFieldsValue,
        setPatientInputFieldsValue,
        inputFields,
        setInputFields,
        inputFieldsValue,
        setInputFieldsValue,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        defaultDate,
        setDefaultDate,
        userSelected,
        setUserSelected,
        clinicName,
        setClinicName,
        selectedCustomDashboard,
        setSelectedCustomDashboard,
        kpiAddAccess,
        setKPIAddAccess,
        ptAcceptFilter,
        setPTAcceptFilter,
        isModalOpen,
        setIsModalOpen,
        msg,
        setMsg,
        clinicReport,
        setClinicReport,
        taxIDValue,
        setTaxIDValue,
        clinicId,
        setClinicId,
        userId,
        setUserId,
        groupByDate,
        setGroupByDate,
        groupByClinic,
        setGroupByClinic,
        isOpd,
        setIsOpd
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppCentralisedDataStore;
