import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  OtpSend: null,
  isLoadingOtpSend: false,
  isErrorOtpSend: null,
  OtpStatus: '',

  //////////////////////
  verifiedOtp: null,
  isLoadingVerifiedOtp: false,
  /////////////
  resetPassword: null,
  isLoadingResetPassword: false,
};

export const fetchSendOtp = createAsyncThunk(
  'sendOtp/sendOtpList',
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post(
        `/api/Auth/authCode/${values}`,
        null
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Sent Otp -https://dentalsoftware.azurewebsites.net/api/Auth/authCode/{email}
// https://dentalsoftware.azurewebsites.net/api/Auth/verify/(Email)/(Code)

export const fetchVerifiedOtp = createAsyncThunk(
  'verifiedOtp/verifiedOtpList',
  async (values, { rejectWithValue }) => {
    //  console.log('values :>> ', values);
    try {
      const data = await axiosInstance.post(
        `/api/Auth/verify/${values.email}/${values.otp}`,
        {
          withCredentials: true,
        }
      );
      // console.log('data :>> ', data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchResetPassword = createAsyncThunk(
  'verifiedOtp/verifiedOtpList',
  async (values, { rejectWithValue }) => {
    // console.log('values :>> ', values);
    try {
      const data = await axiosInstance.post(`api/Auth/resetpassword`, values);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const OtpSendSlice = createSlice({
  name: 'sendOtpList',
  initialState,
  extraReducers: {
    [fetchSendOtp.fulfilled]: (state, { payload }) => {
      // console.log('payload :>> ', payload);
      state.OtpSend = payload;
      state.isLoadingOtpSend = false;
      state.OtpStatus = 'Success';
      state.isErrorOtpSend = null;
    },
    [fetchSendOtp.pending]: (state, { payload }) => {
      state.OtpSend = null;
      state.isLoadingOtpSend = true;
      state.OtpStatus = '';
      state.isErrorOtpSend = null;
    },

    [fetchSendOtp.rejected]: (state, { payload }) => {
      state.OtpSend = null;
      state.isLoadingOtpSend = false;
      state.isErrorOtpSend = payload;
      state.OtpStatus = 'bad req';
    },
    ///////////////////////
    [fetchVerifiedOtp.fulfilled]: (state, { payload2 }) => {
      // console.log('payload2 :>> ', payload2);
      // state.verifiedOtp = payload2;
      state.isLoadingVerifiedOtp = false;
    },
    [fetchVerifiedOtp.pending]: (state, { payload }) => {
      state.verifiedOtp = null;
      state.isLoadingVerifiedOtp = true;
    },
    [fetchVerifiedOtp.rejected]: (state, { payload }) => {
      state.verifiedOtp = null;
      state.isLoadingVerifiedOtp = false;
    },
    //////////////
    [fetchResetPassword.fulfilled]: (state, { payload2 }) => {
      // state.verifiedOtp = payload2;
      state.isLoadingResetPassword = false;
    },
    [fetchResetPassword.pending]: (state, { payload }) => {
      state.verifiedOtp = null;
      state.isLoadingResetPassword = true;
    },
    [fetchResetPassword.rejected]: (state, { payload }) => {
      state.verifiedOtp = null;
      state.isLoadingResetPassword = false;
    },
  },
});

export default OtpSendSlice.reducer;
