import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  insurancePlan: [],
  insurancePlanLoading: true,
  insurancePlanError: false,
};

export const insurancePlanSlice = createSlice({
  name: "locationPlanSlice",
  initialState,
  reducers: {
    insurancePlanPending: (state) => {
      state.insurancePlan = [];
      state.insurancePlanLoading = true;
      state.insurancePlanError = false;
    },
    insurancePlanErrored: (state, { payload }) => {
      state.insurancePlan = [];
      state.insurancePlanLoading = false;
      state.insurancePlanError = payload;
    },
    insurancePlanDetails: (state, { payload }) => {
      state.insurancePlan = payload;
      state.insurancePlanLoading = false;
      state.insurancePlanError = false;
    },
  },
});

// Destructure and export the plain action creators
export const {
  insurancePlanPending,
  insurancePlanErrored,
  insurancePlanDetails,
} = insurancePlanSlice.actions;

export default insurancePlanSlice.reducer;

export const insurancePlanList = (id) => async (dispatch) => {
  try {
    dispatch(insurancePlanPending());
    const { data } = await axiosInstance.get(
      `api/Appointment/get-insurancecarrierplan?id=${id}`
    );

    dispatch(insurancePlanDetails(data));
  } catch (error) {
    dispatch(insurancePlanErrored(true));
  }
};
