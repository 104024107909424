import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  SwitchDashHome: false,
};
const dashBoardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    toggleSwitch: (state, action) => {
      state.SwitchDashHome = action.payload;
    },
  },
});
export const { toggleSwitch } = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
