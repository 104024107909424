import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   PatientFinder: [],
   isLoadingPatientFinder: false,
   statusPatientFinder: "",
   isErrorPatientFinder: null,
};


export const fetchPatientFinder = createAsyncThunk(
   "PatientFinder/PatientFinderList",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(`/api/Revenue/patient-finder?clinicid=${params.inputFields}&status=${params.status}&insId=${params.insurance}&lastvisit=${params.lastVisit}&Gender=${params.gender}&Age=${params.age}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const clearPatientFinder = createAction('facebook/clearPatientFinder');

const PatientFinderSlice = createSlice({
   name: "PatientFinderList",
   initialState,
   reducers: {
      resetData(state, action) {
         return initialState
      }
   },
   extraReducers: {
      [fetchPatientFinder.fulfilled]: (state, { payload }) => {
         state.PatientFinder = payload;
         state.isLoadingPatientFinder = false;
         state.statusPatientFinder = "Success";
         state.isErrorPatientFinder = null;
      },
      [fetchPatientFinder.pending]: (state, { payload }) => {
         state.PatientFinder = [];
         state.isLoadingPatientFinder = true;
         state.statusPatientFinder = "";
         state.isErrorPatientFinder = null;
      },
      [fetchPatientFinder.rejected]: (state, { payload }) => {
         state.PatientFinder = [];
         state.isLoadingPatientFinder = false;
         state.isErrorPatientFinder = payload;
         state.statusPatientFinder = "bad req";
      },
   },
});

export default PatientFinderSlice.reducer;
export const { resetData } = PatientFinderSlice.actions