import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

const LocationModal = ({ item, handleClick, onLocationSelect, editable }) => {
  return (
    <div className="ne_location_modal">
      <div className="py-1 d-flex justify-content-end" onClick={handleClick}>
        <MdOutlineClose color="red" size={15} />
      </div>
      <b>{item?.title}</b>
      <div className="py-2">
        <p className="street_address mt-2">{item?.address}</p>
        <a className="storepoint_popup_phone py-4" href="tel:(254) 285-2014">
          {item?.mob}
        </a>
      </div>

      {editable && (
        <div className="storepoint-extra-btn py-3 d-flex">
          <div className="stpt_website_button" onClick={onLocationSelect}>
            Book Now
          </div>
        </div>
      )}
    </div>
  );
};

const MyMarker = React.memo(
  ({ text, title, $hover, handleClick, ...props }) => {
    return (
      <div className="location_modal_container">
        <FaMapMarkerAlt
          className={`${$hover ? "circle hover" : "circle"} `}
          onClick={handleClick}
        />
        {/* {!props.isSelected && <div className="circle_text">{title}</div>} */}

        {props.isSelected && (
          <LocationModal
            item={props.activeLocation}
            handleClick={handleClick}
            onLocationSelect={props.onLocationSelect}
            editable={props.isEditable}
          />
        )}
      </div>
    );
  }
);

export default MyMarker;
