import React, { useState } from "react";
import { appointmentData } from "../../data/appointmentData/appointmentData";
import { appointmentTypeData } from "../../data/appointmentTypeData/appointmentTypeData";
// import moment from "moment";

export const AppointmentContext = React.createContext();

const AppointmentContextProvider = ({ children }) => {
  // aptStep
  const [selectedCareer, setSelectedCareer] = useState({
    firstStep: true,
    secondStep: false,
    thirdStep: false,
    fourthStep: false,
    finalStep: false,
    success: false,
  });
  const [aptTypBtnTitle, setAptTypBtnTitle] = useState(
    appointmentTypeData[0].title
  );
  // map
  const [pointsData, setPointsData] = useState([]);
  const [bookedAddress, setBookedAddress] = useState(null);
  const [selectedAdd, setSelectedAdd] = useState(null);
  const [bookedTime, setBookedTime] = useState({
    month: "",
    weekName: "",
    day: "",
    year: "",
    time: "",
  });
  // console.log('bookedTime', bookedTime)
  // dateScroll
  // const [activeDays, setActiveDays] = useState([]);
  const [slotId, setSlotId] = useState(0);
  // const [date, setDate] = useState(new Date());
  // const [calendar, setCalendar] = useState(new Date());
  // const [currentMonthArrays, setCurrentMonthArrays] = useState([]);
  // const [activeMonthArrays, setActiveMonthArrays] = useState([]);
  // const [avlMonthSlot, setAvlMonthSlot] = useState([]);
  // const [isActiveMonth, setIsActiveMonth] = useState(true);
  // const [activeIndex, setActiveIndex] = useState(0);
  const [aptTypeData, setAptTypeData] = useState(appointmentData);
  // const [activeMonthCount, setActiveMonthCount] = useState(moment().daysInMonth());
  // insurance
  const [insuranceData, setInsuranceData] = useState({
    career: "",
    careerPlan: "",
  });
  //  book
  const [IsNoInsurance, setIsNoInsurance] = useState(null);
  const [OtherInsurance, setOtherInsurance] = useState(null);
  const [InsuranceID, setInsuranceID] = useState(null);
  const [ScheduledForSelf, setScheduledForSelf] = useState(null);
  const [AppointmentType, setAppointmentType] = useState(null);
  // login

  const [dropStatus, setDropStatus] = useState({
    isCareerField: false,
    isPlanField: false,
    isCareer: false,
    isCareerPlan: false,
  });

  return (
    <AppointmentContext.Provider
      value={{
        selectedCareer,
        setSelectedCareer,
        bookedAddress,
        setBookedAddress,
        bookedTime,
        setBookedTime,
        // activeDays,
        // setActiveDays,
        // currentMonthArrays,
        // setCurrentMonthArrays,
        // date,
        // setDate,
        aptTypBtnTitle,
        setAptTypBtnTitle,
        // activeMonthArrays,
        // setActiveMonthArrays,
        // isActiveMonth,
        // setIsActiveMonth,
        // calendar,
        // setCalendar,
        // activeIndex,
        // setActiveIndex,
        // activeMonthCount,
        // setActiveMonthCount,
        aptTypeData,
        setAptTypeData,
        // avlMonthSlot,
        // setAvlMonthSlot,
        pointsData,
        setPointsData,
        insuranceData,
        setInsuranceData,
        dropStatus,
        setDropStatus,
        setSlotId,
        slotId,
        IsNoInsurance,
        setIsNoInsurance,
        OtherInsurance,
        setOtherInsurance,
        InsuranceID,
        setInsuranceID,
        ScheduledForSelf,
        setScheduledForSelf,
        AppointmentType,
        setAppointmentType,
        selectedAdd,
        setSelectedAdd,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentContextProvider;
