import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   PatientAdded: [],
   isLoadingPatientAdded: false,
   isErrorPatientAdded: null,
   statusPatientAdded: "",
   ////////////////////////
   NPSeenList: [],
   isLoadingNPSeen: false,
   isErrorNPSeen: null,
   statusNPSeen: "",
   /////////////////
   AppmtAdded: [],
   isLoadingAppmtAdded: false,
   isErrorAppmtAdded: null,
   statusAppmtAdded: "",
   TxAccept: [],
   isLoadingTxAccept: false,
   isErrorTxAccept: null,
   statusTxAccept: "",
   PtAccept: [],
   isLoadingPtAccept: false,
   isErrorPtAccept: null,
   statusPtAccept: "",
   AppmtBroken: [],
   isLoadingAppmtBroken: false,
   statusAppmtBroken: "",
   isErrorAppmtBroken: null,
};

export const fetchPatientAdded = createAsyncThunk(
   "practiceIQData/practiceIQDataList",
   async (params, { rejectWithValue }) => {
      // console.log('PatientAddedparams', currentDate)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-NewPatientsAdded?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchNPSeen = createAsyncThunk(
   "practiceIQNPSeen/practiceIQNPSeen",
   async (params, { rejectWithValue }) => {
      // console.log('NPSeenparams', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-NPSEEN?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchAppmtAdded = createAsyncThunk(
   "practiceIQAppmtAdded/practiceIQAppmtAdded",
   async (params, { rejectWithValue }) => {
      // console.log('AppmtAddedparams', currentDate)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-APPMTADDED?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchTxAccept = createAsyncThunk(
   "practiceIQTxAccept/practiceIQTxAccept",
   async (params, { rejectWithValue }) => {
      // console.log('AppmtAddedparams', currentDate)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-TreatmentAcceptancebasic?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPtAccept = createAsyncThunk(
   "practiceIQPtAccept/practiceIQPtAccept",
   async (params, { rejectWithValue }) => {
      // console.log('AppmtAddedparams', currentDate)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-ptacceptbasic?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchAppmtBroken = createAsyncThunk(
   "practiceIQAppmtBroken/practiceIQAppmtBroken",
   async (params, { rejectWithValue }) => {
      // console.log('AppmtBrokenparams', currentDate)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-BrokenAPPMTADDED?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

const practiceIQDashboardSlice = createSlice({
   name: "practiceIQDataList",
   initialState,
   extraReducers: {
      [fetchPatientAdded.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PatientAdded = [];
         } else {
            state.PatientAdded = payload;
         }
         // state.PatientAdded = payload;
         state.isLoadingPatientAdded = false;
         state.statusPatientAdded = "Success";
         state.isErrorPatientAdded = null;
      },
      [fetchPatientAdded.pending]: (state, { payload }) => {
         state.PatientAdded = [];
         state.isLoadingPatientAdded = true;
         state.statusPatientAdded = "";
         state.isErrorPatientAdded = null;
      },
      [fetchPatientAdded.rejected]: (state, { payload }) => {
         state.PatientAdded = [];
         state.isLoadingPatientAdded = false;
         state.isErrorPatientAdded = payload;
         state.statusPatientAdded = "bad req";
      },
      [fetchNPSeen.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.NPSeenList = payload.data;
         } else {
            state.NPSeenList = [];
         }
         // state.NPSeen = payload;
         state.isLoadingNPSeen = false;
         state.statusNPSeen = "Success";
         state.isErrorNPSeen = null;
      },
      [fetchNPSeen.pending]: (state, { payload }) => {
         state.NPSeenList = [];
         state.isLoadingNPSeen = true;
         state.statusNPSeen = "";
         state.isErrorNPSeen = null;
      },
      [fetchNPSeen.rejected]: (state, { payload }) => {
         state.NPSeenList = [];
         state.isLoadingNPSeen = false;
         state.isErrorNPSeen = payload;
         state.statusNPSeen = "bad req";
      },
      [fetchAppmtAdded.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.AppmtAdded = [];
         } else {
            state.AppmtAdded = payload;
         }
         // state.AppmtAdded = payload;
         state.isLoadingAppmtAdded = false;
         state.statusAppmtAdded = "Success";
         state.isErrorAppmtAdded = null;
      },
      [fetchAppmtAdded.pending]: (state, { payload }) => {
         state.AppmtAdded = [];
         state.isLoadingAppmtAdded = true;
         state.statusAppmtAdded = "";
         state.isErrorAppmtAdded = null;
      },
      [fetchAppmtAdded.rejected]: (state, { payload }) => {
         state.AppmtAdded = [];
         state.isLoadingAppmtAdded = false;
         state.isErrorAppmtAdded = payload;
         state.statusAppmtAdded = "bad req";
      },
      [fetchTxAccept.fulfilled]: (state, { payload }) => {
         state.TxAccept = payload;
         state.isLoadingTxAccept = false;
         state.statusTxAccept = "Success";
         state.isErrorTxAccept = null;
      },
      [fetchTxAccept.pending]: (state, { payload }) => {
         state.TxAccept = [];
         state.isLoadingTxAccept = true;
         state.statusTxAccept = "";
         state.isErrorTxAccept = null;
      },
      [fetchTxAccept.rejected]: (state, { payload }) => {
         state.TxAccept = [];
         state.isLoadingTxAccept = false;
         state.isErrorTxAccept = payload;
         state.statusTxAccept = "bad req";
      },
      [fetchPtAccept.fulfilled]: (state, { payload }) => {
         state.PtAccept = payload;
         state.isLoadingPtAccept = false;
         state.statusPtAccept = "Success";
         state.isErrorPtAccept = null;
      },
      [fetchPtAccept.pending]: (state, { payload }) => {
         state.PtAccept = [];
         state.isLoadingPtAccept = true;
         state.statusPtAccept = "";
         state.isErrorPtAccept = null;
      },
      [fetchPtAccept.rejected]: (state, { payload }) => {
         state.PtAccept = [];
         state.isLoadingPtAccept = false;
         state.isErrorPtAccept = payload;
         state.statusPtAccept = "bad req";
      },
      [fetchAppmtBroken.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.AppmtBroken = [];
         } else {
            state.AppmtBroken = payload;
         }
         // state.AppmtBroken = payload;
         state.isLoadingAppmtBroken = false;
         state.statusAppmtBroken = "Success";
         state.isErrorAppmtBroken = null;
      },
      [fetchAppmtBroken.pending]: (state, { payload }) => {
         state.AppmtBroken = [];
         state.isLoadingAppmtBroken = true;
         state.statusAppmtBroken = "";
         state.isErrorAppmtBroken = null;
      },
      [fetchAppmtBroken.rejected]: (state, { payload }) => {
         state.AppmtBroken = [];
         state.isLoadingAppmtBroken = false;
         state.isErrorAppmtBroken = payload;
         state.statusAppmtBroken = "bad req";
      },
   },
});


export default practiceIQDashboardSlice.reducer;
