import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   PatientFollowUp: [],
   isLoadingPatientFollowUp: false,
   isErrorPatientFollowUp: null,
   statusPatientFollowUp: "",
   // ------------------------
   AddEditCampaign: [],
   isLoadingAddEditCampaign: false,
   statusAddEditCampaign: "",
   isErrorAddEditCampaign: null,
   // ------------------------
   statusCampByDate: "",
   isLoadingCampByDate: false,
   isErrorCampByDate: null,
   // ---------------------------
   FollowUpServices: [],
   isLoadingFollowUpServices: false,
   isErrorFollowUpServices: null,
   statusFollowUpServices: "",
   // -----------------------------
   FollowUpCampData: [],
   isLoadingFollowUpCampData: false,
   statusFollowUpCampData: "",
   isErrorFollowUpCampData: null,
   // -----------------------------
   GHLData: [],
   isLoadingGHL: false,
   isErrorGHL: null,
   statusGHL: "",
   //--------------------------
   RunCampaign: {},
   isLoadingRunCampaign: false,
   statusRunCampaign: "",
   isErrorRunCampaign: null,
};


export const fetchPatientFollowUp = createAsyncThunk(
   "patientFollowUp/patientFollowUpList",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(`/api/Revenue/get-Campaigns?ClinicId=${params}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchAddEditCampaign = createAsyncThunk(
   "campaign/addCampaign",
   async (values, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.post(
            "/api/Revenue/Post-Campaigns",
            values
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const fetchSendGHL = createAsyncThunk(
   "campaignGHL/addCampaignGHL",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const data = await axiosInstance.post(`/api/Revenue/Run-Campaign?id=${params.id}&run=${params.run}`);
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const fetchEditCampByDate = createAsyncThunk(
   "campaignupdate/addCampaignupdate",
   async (value, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.post(
            `/api/revenue/put-CampaignDate?cid=${value.id}&startdate=${value.StartDate}&enddate=${value.EndDate}`,
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const fetchFollowUpServices = createAsyncThunk(
   "FollowUpServices/FollowUpServicesList",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(`/api/Revenue/get-CodeServices?cid=${params.id}&ClinicId=${params.inputFields}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchFollowUpCampData = createAsyncThunk(
   "FollowUpCampData/FollowUpCampDataList",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(`/api/Revenue/get-Camp?Id=${params}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchRunCampaign = createAsyncThunk(
   "runCampaign/runCampaignList",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.post(`/api/Revenue/Run-Campaign?id=${params}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);


const FollowUpSlice = createSlice({
   name: "FollowUpCampaignList",
   initialState,
   extraReducers: {
      [fetchPatientFollowUp.fulfilled]: (state, { payload }) => {
         state.PatientFollowUp = payload;
         state.isLoadingPatientFollowUp = false;
         state.statusPatientFollowUp = "Success";
         state.isErrorPatientFollowUp = null;
      },
      [fetchPatientFollowUp.pending]: (state, { payload }) => {
         state.PatientFollowUp = [];
         state.isLoadingPatientFollowUp = true;
         state.statusPatientFollowUp = "";
         state.isErrorPatientFollowUp = null;
      },
      [fetchPatientFollowUp.rejected]: (state, { payload }) => {
         state.PatientFollowUp = [];
         state.isLoadingPatientFollowUp = false;
         state.isErrorPatientFollowUp = payload;
         state.statusPatientFollowUp = "bad req";
      },
      [fetchAddEditCampaign.fulfilled]: (state, { payload }) => {
         state.AddEditCampaign = payload;
         state.isLoadingAddEditCampaign = false;
         state.statusAddEditCampaign = "Success";
         state.isErrorAddEditCampaign = null;
      },
      [fetchAddEditCampaign.pending]: (state, { payload }) => {
         state.AddEditCampaign = [];
         state.isLoadingAddEditCampaign = true;
         state.statusAddEditCampaign = "";
         state.isErrorAddEditCampaign = null;
      },
      [fetchAddEditCampaign.rejected]: (state, { payload }) => {
         state.AddEditCampaign = [];
         state.isLoadingAddEditCampaign = false;
         state.isErrorAddEditCampaign = payload;
         state.statusAddEditCampaign = payload;
      },
      [fetchEditCampByDate.fulfilled]: (state, { payload }) => {
         state.isLoadingCampByDate = false;
         state.statusCampByDate = "Success";
         state.isErrorCampByDate = null;
      },
      [fetchEditCampByDate.pending]: (state, { payload }) => {
         state.isLoadingCampByDate = true;
         state.statusCampByDate = "";
         state.isErrorCampByDate = null;
      },
      [fetchEditCampByDate.rejected]: (state, { payload }) => {
         state.isLoadingCampByDate = false;
         state.isErrorCampByDate = payload;
         state.statusCampByDate = payload;
      },
      [fetchFollowUpServices.fulfilled]: (state, { payload }) => {
         state.FollowUpServices = payload;
         state.isLoadingFollowUpServices = false;
         state.statusFollowUpServices = "Success";
         state.isErrorFollowUpServices = null;
      },
      [fetchFollowUpServices.pending]: (state, { payload }) => {
         state.FollowUpServices = [];
         state.isLoadingFollowUpServices = true;
         state.statusFollowUpServices = "";
         state.isErrorFollowUpServices = null;
      },
      [fetchFollowUpServices.rejected]: (state, { payload }) => {
         state.FollowUpServices = [];
         state.isLoadingFollowUpServices = false;
         state.isErrorFollowUpServices = payload;
         state.statusFollowUpServices = "bad req";
      },
      [fetchFollowUpCampData.fulfilled]: (state, { payload }) => {
         state.FollowUpCampData = payload;
         state.isLoadingFollowUpCampData = false;
         state.statusFollowUpCampData = "Success";
         state.isErrorFollowUpCampData = null;
      },
      [fetchFollowUpCampData.pending]: (state, { payload }) => {
         state.FollowUpCampData = [];
         state.isLoadingFollowUpCampData = true;
         state.statusFollowUpCampData = "";
         state.isErrorFollowUpCampData = null;
      },
      [fetchFollowUpCampData.rejected]: (state, { payload }) => {
         state.FollowUpCampData = [];
         state.isLoadingFollowUpCampData = false;
         state.isErrorFollowUpCampData = payload;
         state.statusFollowUpCampData = "bad req";
      },
      [fetchSendGHL.fulfilled]: (state, { payload }) => {
         state.GHLData = payload;
         state.isLoadingGHL = false;
         state.statusGHL = "Success";
         state.isErrorGHL = null;
      },
      [fetchSendGHL.pending]: (state, { payload }) => {
         state.GHLData = [];
         state.isLoadingGHL = true;
         state.statusGHL = "";
         state.isErrorGHL = null;
      },
      [fetchSendGHL.rejected]: (state, { payload }) => {
         state.GHLData = [];
         state.isLoadingGHL = false;
         state.isErrorGHL = payload;
         state.statusGHL = "bad req";
      },
      [fetchRunCampaign.fulfilled]: (state, { payload }) => {
         state.RunCampaign = payload;
         state.isLoadingRunCampaign = false;
         state.statusRunCampaign = "Success";
         state.isErrorRunCampaign = null;
      },
      [fetchRunCampaign.pending]: (state, { payload }) => {
         state.RunCampaign = {};
         state.isLoadingRunCampaign = true;
         state.statusRunCampaign = "";
         state.isErrorRunCampaign = null;
      },
      [fetchRunCampaign.rejected]: (state, { payload }) => {
         state.RunCampaign = {};
         state.isLoadingRunCampaign = false;
         state.isErrorRunCampaign = payload;
         state.statusRunCampaign = "bad req";
      },
   },
});

export default FollowUpSlice.reducer;
