import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  ProvidersState: [],
  isErrorProvider: null,
  isLoadingProvider: false,
  statusProvider: '',
};

export const getDoctorDetails = createAsyncThunk(
  'providers',
  async (values, { rejectWithValue }) => {
    try {
      const data = axiosInstance.post(
        `/api/Dashboard/providers/${values.clinicid}/upsertprov`,
        values
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// https://api.dentalbookingonline.com/api/Dashboard/providers/66/upsertprov

// body

// {
// "clinicid": 66 ,
// "ProvIds": [101, 102, 103]

// }

const DoctorDetailsSlice = createSlice({
  name: ' ProvidersState',
  initialState,
  extraReducers: {
    [getDoctorDetails.fulfilled]: (state, action) => {
      state.ProvidersState = action.payload;
      state.isLoadingProvider = false;
      state.statusProvider = 'Success';
      state.isErrorProvider = '';
    },
    [getDoctorDetails.pending]: (state, action) => {
      state.ProvidersState = [];
      state.isLoadingProvider = true;
      state.statusProvider = 'pending';
      state.isErrorProvider = '';
    },
    [getDoctorDetails.rejected]: (state, action) => {
      state.ProvidersState = [];
      state.isLoadingProvider = false;
      state.statusProvider = 'failed';
      state.isErrorProvider = 'failed';
    },
  },
});

export default DoctorDetailsSlice.reducer;
