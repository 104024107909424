import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  locationList: [],
  locationLoading: true,
  locationError: false,
};

export const locationCareerSlice = createSlice({
  name: "acType",
  initialState,
  reducers: {
    locationPending: (state) => {
      state.locationList = [];
      state.locationLoading = true;
      state.locationError = false;
    },
    locationCareerErrored: (state, { payload }) => {
      state.locationList = [];
      state.locationLoading = false;
      state.locationError = payload;
    },
    locationDetails: (state, { payload }) => {
      state.locationList = payload;
      state.locationLoading = false;
      state.locationError = false;
    },
  },
});

// Destructure and export the plain action creators
export const { locationPending, locationCareerErrored, locationDetails } =
  locationCareerSlice.actions;

export default locationCareerSlice.reducer;

export const getLocationList = () => async (dispatch) => {
  try {
    dispatch(locationPending());
    const { data } = await axiosInstance.get(`api/front/activeclinics?Active=True`);

    dispatch(locationDetails(data));
  } catch (error) {
    dispatch(locationCareerErrored(true));
  }
};
