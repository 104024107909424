import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   status: "",
   isLoading: false,
   message: "",
   isError: null,
};

export const fetchEditAppointment = createAsyncThunk(
   "Appointment/EditAppointment",
   async (values, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.post(
            "/api/Patient/Edit-Patients",
            values
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const editAppointSlice = createSlice({
   name: "editAppoint",
   initialState,
   extraReducers: {
      [fetchEditAppointment.fulfilled]: (state, { payload }) => {
         state.isLoading = false;
         state.status = "Success";
         state.isError = null;
      },
      [fetchEditAppointment.pending]: (state, { payload }) => {
         state.isLoading = true;
         state.status = "";
         state.isError = null;
      },
      [fetchEditAppointment.rejected]: (state, { payload }) => {
         state.isLoading = false;
         state.isError = payload;
         state.status = payload;
      },
   },
});

export default editAppointSlice.reducer;