import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { Navigate, useNavigate } from "react-router-dom";
import { AppointmentContext } from "../../../context/AppointmentBook/AppointmentBook";
// import { appointmentData } from "../../../data/appointmentData/appointmentData";
import { appointmentTypeData } from "../../../data/appointmentTypeData/appointmentTypeData";
// import { timeListData } from "../../../data/doctorListData/doctorListData";
import { selTimeList } from "../../../redux/timeSlotSlice/timeSlot";
import Button from "../../../UI/Button/Button";

const ThankYou = () => {
  // const navigate = useNavigate();
  const {
    bookedAddress,
    selectedCareer,
    setSelectedCareer,
    // setIsActiveMonth,
    setInsuranceData,
    setDropStatus,
    setAptTypeData,
  } = useContext(AppointmentContext);
  const { timeList } = useSelector((state) => state.timeSlot);

  const dispatch = useDispatch();

  const { locationList, locationLoading } = useSelector(
    (state) => state.locationList
  );

  const onBookApt = () => {
    const tempApp = [...appointmentTypeData];
    tempApp.map((typeItem, typeIdx) => (typeItem.isSelect = false));
    setAptTypeData(tempApp);
    setInsuranceData({
      career: "",
      careerPlan: "",
    });

    const tempTimeList = [...timeList];
    const felTimeList = tempTimeList.map((tItem, tdx) => ({
      ...tItem,
      selected: false,
    }));
    dispatch(selTimeList(felTimeList));

    setDropStatus({
      isCareerField: false,
      isPlanField: false,
      isCareer: false,
      isCareerPlan: false,
    });
    setSelectedCareer({
      ...selectedCareer,
      finalStep: false,
      success: false,
      firstStep: true,
    });
    // setIsActiveMonth(true);
  };

  useEffect(() => {
    const data = locationList.filter((r) => r.id === bookedAddress.id);
    const { redirectionUrlOnAppointment } = Object.assign({}, ...data);
    // console.log("redirectionUrlOnAppointment", redirectionUrlOnAppointment);
    redirectionUrlOnAppointment &&
      setTimeout(() => {
        // window.open(url.redirectionUrlOnAppointment);
        window.location.href = `http://${redirectionUrlOnAppointment}`;
      }, 3000);
  }, []);
  // useEffect(() => {}, []);

  return (
    <div>
      <Row className=" w-100">
        <Col
          md={12}
          className="d-flex justify-content-center align-items-center w-100 mt-5"
        >
          <div>
            <div className="text-center">
              <img
                src="/assets/img/checked/checked.png"
                alt="dental-success"
                width="250"
              />
            </div>
            <div>
              <h4 className="mb-3 ne_review_title text-center">
                Your appointment has been booked successfully!
              </h4>
              <p className="ne_change mb-3 text-center">
                We have sent the booking details to your registered email
                address.{" "}
              </p>
            </div>
          </div>
        </Col>
        <Col className="w-100 mt-4 d-flex justify-content-center align-items-center">
          <Button
            title="Book Appointment Again"
            className="ne_btn ne_btn_primary d-flex justify-content-center align-items-center"
            onClick={onBookApt}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ThankYou;
