import axiosInstance from "../helper/axiosInstance/axiosInstance";
import { decode } from "js-base64";
import Cookies from 'js-cookie';

const setSession = (token) => {
  if (token) {
    localStorage.setItem("_t", token);
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    localStorage.removeItem("_t");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("expTime");
    localStorage.removeItem("lastRoute");
    // delete axios.defaults.headers.common["Authorization"];
  }
};
// const setSession = (token) =>
//   token ? localStorage.setItem("_t", token) : localStorage.removeItem("_t");

export const defaultLogin = async (values) => {
  const { data } = await axiosInstance.post(`api/Auth/login`, values);
  data.token && setSession(data.token);
  return data;
};
export const defaultLogOut = async () => setSession(null);

export const defaultSignUp = async (values) => {
  const { data } = await axiosInstance.post(`api/Account/register`, values);
  return data;
};

// user
export const getUser = () => {
  const token = localStorage.getItem("_t");
  if (!token) return false;
  return JSON.parse(decode(token.split(".")[1]));
};


export const logout = () => {
  Cookies.remove('user');
};

export const getLastLoggegInUser = () => {
  const user = Cookies.get('user');
  return user ? JSON.parse(user) : null;
};