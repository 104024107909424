import React from "react";

const Logo = () => {
  return (
    <div className="w-100 text-center lg-mg-ver">
      <img src="/assets/img/logo/logo-1.png" alt="dental-logo" width="250" />
    </div>
  );
};

export default Logo;
