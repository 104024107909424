import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  grossData: [],
  isLoadinggrossData: false,
  isError: null,
  status: '',
  ///////////////////////////////
  doctorDashBoard: [],
  isLoadingdoctorDashBoard: false,
  statusdoctorDashBoard: 'Success',
  isErrordoctorDashBoard: null,

  //graph
  grossGraphData: [],
  isLoadinggrossGraphData: false,
  isGraphError: null,
};

export const fetchGross = createAsyncThunk(
  'gross/gross',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/Dashboard/providers/${params.inputFields}/grossdetails/${params.startDate}/${params.endDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchDoctorDashBoard = createAsyncThunk(
  'gross/grossList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Dashboard/providers/${params.inputFields}/amountdetails/${params.startDate}/${params.endDate}/true`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchGrossGraphData = createAsyncThunk(
  'gross/grossgraph',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Dashboard/providers/${params.inputFields}/grossgraph/${params.startDate}/${params.endDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const grossSlice = createSlice({
  name: 'grossList',
  initialState,
  extraReducers: {
    [fetchGross.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.grossData = payload.data;
      } else {
        state.grossData = [];
      }
      state.isLoadinggrossData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchGross.pending]: (state, { payload }) => {
      state.isLoadinggrossData = true;
      state.status = '';
      state.isError = null;
      state.grossData = [];
    },
    [fetchGross.rejected]: (state, { payload }) => {
      state.isLoadinggrossData = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.grossData = [];
    },
    [fetchDoctorDashBoard.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.doctorDashBoard = payload.data;
      } else {
        state.doctorDashBoard = [];
      }
      state.isLoadingdoctorDashBoard = false;
      state.statusdoctorDashBoard = 'Success';
      state.isErrordoctorDashBoard = null;
    },
    [fetchDoctorDashBoard.pending]: (state, { payload }) => {
      state.isLoadingdoctorDashBoard = true;
      state.statusdoctorDashBoard = '';
      state.isErrordoctorDashBoard = null;
      state.doctorDashBoard = [];
    },
    [fetchDoctorDashBoard.rejected]: (state, { payload }) => {
      state.isLoadingdoctorDashBoard = false;
      state.isErrordoctorDashBoard = payload;
      state.statusdoctorDashBoard = 'Rejected';
      state.doctorDashBoard = [];
    },
    // grossGraphData: [],
    // isLoadinggrossGraphData: false,
    // isGraphError: null,
    [fetchGrossGraphData.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.grossGraphData = payload?.data;
      } else {
        state.grossGraphData = [];
      }
      state.isLoadinggrossGraphData = false;
      state.graphStatus = 'Success';
      state.isGraphError = null;
    },
    [fetchGrossGraphData.pending]: (state, { payload }) => {
      state.grossGraphData = [];

      state.isLoadinggrossGraphData = true;
      state.graphStatus = null;
      state.isGraphError = null;
    },
    [fetchGrossGraphData.rejected]: (state, { payload }) => {
      state.grossGraphData = [];
      state.isLoadinggrossGraphData = false;
      state.graphStatus = 'error';
      state.isGraphError = null;
    },
  },
});

// export const { updateUser } = GrossSlice.actions;

export default grossSlice.reducer;
