import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   insuranceList: [],
   isLoadingInsuranceList: false,
   isErrorInsuranceList: null,
   statusInsuranceList: "",

   insurancePatient: [],
   isLoadingInsurancePatient: false,
   statusInsurancePatient: "",
   isErrorInsurancePatient: null,

   insuranceBasic: [],
   isLoadingInsuranceBasic: false,
   statusInsuranceBasic: "",
   isErrorInsuranceBasic: null,

   checkEligibility: [],
   isLoadingCheckEligibility: false,
   statusCheckEligibility: "",
   isErrorCheckEligibility: null,
};

export const fetchInsuranceList = createAsyncThunk(
   "insurance/insuranceList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/Insurance/get-insCarrier?clinicid=${params}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchInsurancePatient = createAsyncThunk(
   "insurancePatient/insurancePatientList",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(
            `/api/Insurance/get-Insurancepatients?clinicid=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchInsuranceBasic = createAsyncThunk(
   "insurance/insuranceBasic",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(
            `api/Insurance/get-Insbasic?clinicid=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchCheckEligibility = createAsyncThunk(
   "eligibility/checkEligibility",
   async (values, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.post(
            "/api/Insurance/check-eligibility",
            values
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const insuranceSlice = createSlice({
   name: "insurance",
   initialState,
   extraReducers: {
      [fetchInsuranceList.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.insuranceList = payload.data;
         } else {
            state.insuranceList = [];
         }
         state.isLoadingInsuranceList = false;
         state.statusInsuranceList = "Success";
         state.isErrorInsuranceList = null;
      },
      [fetchInsuranceList.pending]: (state, { payload }) => {
         state.insuranceList = [];
         state.isLoadingInsuranceList = true;
         state.statusInsuranceList = "";
         state.isErrorInsuranceList = null;
      },
      [fetchInsuranceList.rejected]: (state, { payload }) => {
         state.insuranceList = [];
         state.isLoadingInsuranceList = false;
         state.isErrorInsuranceList = payload;
         state.statusInsuranceList = "rejected";
      },
      [fetchInsurancePatient.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.insurancePatient = payload.data;
         } else {
            state.insurancePatient = [];
         }
         state.isLoadingInsurancePatient = false;
         state.statusInsurancePatient = "Success";
         state.isErrorInsurancePatient = null;
      },
      [fetchInsurancePatient.pending]: (state, { payload }) => {
         state.insurancePatient = [];
         state.isLoadingInsurancePatient = true;
         state.statusInsurancePatient = "";
         state.isErrorInsurancePatient = null;
      },
      [fetchInsurancePatient.rejected]: (state, { payload }) => {
         state.insurancePatient = [];
         state.isLoadingInsurancePatient = false;
         state.isErrorInsurancePatient = payload;
         state.statusInsurancePatient = "rejected";
      },
      [fetchInsuranceBasic.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.insuranceBasic = payload.data;
         } else {
            state.insuranceBasic = [];
         }
         state.isLoadingInsuranceBasic = false;
         state.statusInsuranceBasic = "Success";
         state.isErrorInsuranceBasic = null;
      },
      [fetchInsuranceBasic.pending]: (state, { payload }) => {
         state.insuranceBasic = [];
         state.isLoadingInsuranceBasic = true;
         state.statusInsuranceBasic = "";
         state.isErrorInsuranceBasic = null;
      },
      [fetchInsuranceBasic.rejected]: (state, { payload }) => {
         state.insuranceBasic = [];
         state.isLoadingInsuranceBasic = false;
         state.isErrorInsuranceBasic = payload;
         state.statusInsuranceBasic = "rejected";
      },
      [fetchCheckEligibility.fulfilled]: (state, { payload }) => {
         state.checkEligibility = payload;
         state.isLoadingCheckEligibility = false;
         state.statusCheckEligibility = "Success";
         state.isErrorCheckEligibility = null;
      },
      [fetchCheckEligibility.pending]: (state, { payload }) => {
         state.checkEligibility = [];
         state.isLoadingCheckEligibility = true;
         state.statusCheckEligibility = "";
         state.isErrorCheckEligibility = null;
      },
      [fetchCheckEligibility.rejected]: (state, { payload }) => {
         state.checkEligibility = [];
         state.isLoadingCheckEligibility = false;
         state.isErrorCheckEligibility = payload;
         state.statusCheckEligibility = payload;
      },
   },
});

export default insuranceSlice.reducer;
