import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   paymentsList: [],
   isLoading: false,
   isError: null,
   status: "",
   paymentDetailsList: [],
   paymentDetailsIsLoading: false,
   paymentDetailsStatus: "",
   paymentDetailsIsError: null
};

export const fetchPayments = createAsyncThunk(
   "payments/paymentsList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/Practice/get-payments?id=${params.inputFields}&startDate=${params.startDate}&endDate=${params.endDate}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPaymentDetails = createAsyncThunk(
   "paymentDetails/paymentDetailsList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/Api/Practice/get-paymentsBypatient?clinicId=${params.clinicID}&startDate=${params.startDate}&endDate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

const paymentsSlice = createSlice({
   name: "paymentsList",
   initialState,
   extraReducers: {
      [fetchPayments.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.paymentsList = payload.data;
         } else {
            state.paymentsList = [];
         }
         state.isLoading = false;
         state.status = "Success";
         state.isError = null;
      },
      [fetchPayments.pending]: (state, { payload }) => {
         state.paymentsList = [];
         state.isLoading = true;
         state.status = "";
         state.isError = null;
      },
      [fetchPayments.rejected]: (state, { payload }) => {
         state.paymentsList = [];
         state.isLoading = false;
         state.isError = payload;
         state.status = "bad req";
      },
      [fetchPaymentDetails.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.paymentDetailsList = payload.data;
         } else {
            state.paymentDetailsList = [];
         }
         state.paymentDetailsIsLoading = false;
         state.paymentDetailsStatus = "Success";
         state.paymentDetailsIsError = false;
      },
      [fetchPaymentDetails.pending]: (state, { payload }) => {
         state.paymentDetailsList = [];
         state.paymentDetailsIsLoading = true;
         state.paymentDetailsStatus = "";
         state.paymentDetailsIsError = false;
      },
      [fetchPaymentDetails.rejected]: (state, { payload }) => {
         state.paymentDetailsList = [];
         state.paymentDetailsIsLoading = false;
         state.paymentDetailsIsError = true;
         state.paymentDetailsStatus = "Rejected";
      },
   },
});

export default paymentsSlice.reducer;