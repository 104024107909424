import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  insuranceList: [],
  isLoading: false,
  isError: null,
  status: "",
};

export const fetchInsuranceList = createAsyncThunk(
  "insurance/insuranceList",
  async (id = null, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Practice/get-insuranceplans?id=66`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

const practiceSlice = createSlice({
  name: "practice",
  initialState,
  extraReducers: {
    [fetchInsuranceList.fulfilled]: (state, { payload }) => {
      state.insuranceList = payload;
      state.isLoading = false;
      state.status = "Success";
      state.isError = null;
    },
    [fetchInsuranceList.pending]: (state, { payload }) => {
      state.insuranceList = [];
      state.isLoading = true;
      state.status = "";
      state.isError = null;
    },
    [fetchInsuranceList.rejected]: (state, { payload }) => {
      state.insuranceList = [];
      state.isLoading = false;
      state.isError = payload;
      state.status = "bad req";
    },
  },
});

export default practiceSlice.reducer;
