import { useEffect } from 'react';
import './App.scss';
import 'antd/dist/antd.min.css';
import AppointmentContextProvider from './context/AppointmentBook/AppointmentBook';
import Navigation from './route/route';
import { defaultLogOut, getUser } from './services';
function App() {
  const token = localStorage.getItem('_t');

  useEffect(() => {
    const checkExpTimeAndLogOut = () => {
      const expTime = localStorage.getItem('expTime');

      // If expTime is not null or undefined
      if (expTime) {
        // Convert expTime to a Date object
        const expTimeDate = new Date(expTime);

        // Get the current time as a Date object
        const currentTime = new Date();

        // Check if the current time is equal to or past the expiration time
        if (currentTime >= expTimeDate) {
          defaultLogOut();
        }
      }
    };

    // Run the check immediately in case the page is reloaded and the token is still present
    checkExpTimeAndLogOut();

    // Set up an interval to regularly check the expiration time
    const intervalId = setInterval(checkExpTimeAndLogOut, 1000); // Check every 1 second

    // Clean up the interval when the component unmounts or the token changes
    return () => clearInterval(intervalId);
  }, [token]); // Depend on the token

  return (
    <div>
      <AppointmentContextProvider>
        {/* <AppointmentBook /> */}
        <Navigation />
      </AppointmentContextProvider>
    </div>
  );
}

export default App;
