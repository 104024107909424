import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { AppointmentContext } from "../../../context/AppointmentBook/AppointmentBook";
import Logo from "../../../UI/Logo/Logo";
import AppointmentDate from "../AppointmentDate/AppointmentDate";
import AppointmentList from "../AppointmentList/AppointmentList";
import AppointmentTime from "../AppointmentTime/AppointmentTime";
import Location from "../Location/Location";
import DoctorCard from "../../../UI/Card/DoctorCard/DoctorCard";

const AppointmentDetails = () => {
  const { selectedCareer, setSelectedCareer } = useContext(AppointmentContext);
  const { bookedAddress } = useContext(AppointmentContext);

  return (
    <div className="appointment_details w-100 px-0" style={{ overflowY: "auto" }}>
      <div className="timezone_edt  w-100 d-flex align-items-center justify-content-end ">
        <p className="mx-2 mt-1 p-0 m-0" style={{ fontSize: "11.1px" }}>
          These time slots are on timezone EDT
        </p>
        <span className="mr-3 ">
          <AiOutlineClockCircle size={20} />
        </span>
      </div>
      <Col className="mt-5  mb-5 ne_widget-appt-type">
        <Logo />
      </Col>
      <Col className="d-flex p-4 ne-app-list justify-content-between align-items-center mt-5 w-100 ">
        <p className="ne_change mb-0 cursors"
          onClick={() => {
            setSelectedCareer({
              ...selectedCareer,
              firstStep: true,
              secondStep: true,
              thirdStep: false,
            });
          }}
        >
          <BiArrowBack style={{ marginRight: 5 }} />
          Change your appointment schedule details

        </p>
        <AppointmentList />
      </Col>
      <Col className="d-flex-column p-0 ne-app-location justify-content-start mt-5 w-100">
        <Location />
        <DoctorCard name="Doctor" team={bookedAddress.title} />
      </Col>
      <Col className="d-flex p-4 ne-app-date justify-content-start w-100">
        <AppointmentDate />
      </Col>
      <Col className="d-flex pl-3 ne-app-list justify-content-start w-100">
        <AppointmentTime />
      </Col>
    </div>
  );
};

export default AppointmentDetails;
