import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   serviceCode: [],
   isLoadingServiceCode: false,
   isErrorServiceCode: null,
   statusServiceCode: "",
   //////////////////////////////
   serviceCodeLists: [],
   isLoadingCodeLists: false,
   statusCodeLists: "",
   isErrorCodeLists: null,
   // ------------------------
   statusEditServiceCode: "",
   isLoadingEditServiceCode: false,
   isErrorEditServiceCode: null,
};

export const fetchServiceCode = createAsyncThunk(
   "serviceCode/serviceCodeList",
   async (value, { rejectWithValue }) => {
      // console.log('value', value)
      try {
         const { data } = await axiosInstance.get(
            `/api/Revenue/get-ServicesTypes?ClinicId=${value}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("data Not Found");
      }
   }
);

export const fetchServiceCodeLists = createAsyncThunk(
   "serviceCodes/serviceCodeLists",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/Revenue/get-servicecodesinfo?Id=${params.row.id}&ClinicId=${params.inputFields}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("data Not Found");
      }
   }
);

export const fetchEditService = createAsyncThunk(
   "editService/editServiceCode",
   async (values, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.post(
            "/api/Revenue/put-servicetype",
            values
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const settingSlice = createSlice({
   name: "setting",
   initialState,
   extraReducers: {
      [fetchServiceCode.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.serviceCode = [];
         } else {
            state.serviceCode = payload;
         }
         state.isLoadingServiceCode = false;
         state.statusServiceCode = "Success";
         state.isErrorServiceCode = null
      },
      [fetchServiceCode.pending]: (state, { payload }) => {
         state.serviceCode = [];
         state.isLoadingServiceCode = true;
         state.statusServiceCode = "";
         state.isErrorServiceCode = null;
      },
      [fetchServiceCode.rejected]: (state, { payload }) => {
         state.serviceCode = [];
         state.isLoadingServiceCode = false;
         state.isErrorServiceCode = payload;
         state.statusServiceCode = "bad req";
      },
      [fetchServiceCodeLists.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.serviceCodeLists = [];
         } else {
            state.serviceCodeLists = payload;
         }
         state.isLoadingCodeLists = false;
         state.statusCodeLists = "Success";
         state.isErrorCodeLists = null
      },
      [fetchServiceCodeLists.pending]: (state, { payload }) => {
         state.serviceCodeLists = [];
         state.isLoadingCodeLists = true;
         state.statusCodeLists = "";
         state.isErrorCodeLists = null;
      },
      [fetchServiceCodeLists.rejected]: (state, { payload }) => {
         state.serviceCodeLists = [];
         state.isLoadingCodeLists = false;
         state.isErrorCodeLists = payload;
         state.statusCodeLists = "bad req";
      },
      [fetchEditService.fulfilled]: (state, { payload }) => {
         state.isLoadingEditServiceCode = false;
         state.statusEditServiceCode = "Success";
         state.isErrorEditServiceCode = null;
      },
      [fetchEditService.pending]: (state, { payload }) => {
         state.isLoadingEditServiceCode = true;
         state.statusEditServiceCode = "";
         state.isErrorEditServiceCode = null;
      },
      [fetchEditService.rejected]: (state, { payload }) => {
         state.isLoadingEditServiceCode = false;
         state.isErrorEditServiceCode = payload;
         state.statusEditServiceCode = payload;
      },
   },
});

export default settingSlice.reducer;
